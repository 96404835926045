.youngContainer {
display:flex;
flex-wrap:wrap;
padding:10px;
justify-content: space-around;
align-items: center;
color: #f9F9F9;

}

.wrappy {
  
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 300px;
  object-fit: cover;

}

.vignettes {
  
  position:relative;
  height: 450px;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
}
/*
.carte{
  position:absolute;
  top:0;
  right:0;
  background-color:#fff;
  color:white;
}
*/

.imgLayer {
  /* 
  
  bottom: 0;
  left: 0;
 
  right: 0; */

  /* 
   */
   position: absolute; 
   top: 0; 

  background: white;
  color: black;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s, visibility 0.2s;
  height: 100%;
  width: 100%;
  
}

.wrappy:hover .imgLayer {
  visibility: visible;
  opacity: 0.8;
  cursor: pointer;
}

.wrappy:active {
  visibility: visible;
  opacity: 0.8;
  cursor: pointer;
}

.opName {
  
  color:#6D8299;
  
  transition: 0.2s;
  transform: translateY(1em);
  text-align: center;

  border-top: 1px solid;
  border-bottom: 1px solid;
  font-size: 30px;
  width: 100%;
  padding: 15px;
  background: white;
  object-fit: contain;
}

.wrappy:hover .opName {
  transform: translateY(0);
}

/* .vignettes */

@media (max-width: 991px) {
  .vignettes {
    object-fit:cover;
    height: 200px;
    width:200px;
    display:grid;
    grid-template-columns:1fr;
    grid-template-rows: auto;
  }
}

.lostInTranslation {
  max-width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
 
}

.lego {
  /* padding: 10px 100px; */
  color: #6d8299;
  display: flex;
  justify-content: center;
  z-index: index 999;

  font-size: 20px;
  text-align: center;
  visibility: hidden;
  font-weight: 200;
  margin: 0 20px;
}

.lego:hover {
  color: #eddcd2;
}

@media (max-width: 900px) {
  .lego {
    visibility: visible;
  }
}

.ghostdiv {
  padding-bottom: 100px;
  min-height:100%;
 
}
