.gallery {
   
    margin-left: auto;
    margin-right: auto;
    width:100%;
  -webkit-column-count: 4;
  -webkit-column-width: 40%;
  column-count: 4;
  column-width: 40%;

  padding: 0 12px;

  /* 
  
  -moz-column-count: 3;
  column-width: 33%;
   */
}



.gallery .pics {
   max-width:100%;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px; 
}

.gallery .pics:hover {
  filter: opacity(0.8);
  transform: scale(1.1);
  transition: 0.5s ease;
  z-index: 10;
}


@media (min-height: 1200px) {
  .gallery {
    column-count: 2;
    
  }
}

@media (max-width: 991px) {
  .gallery {
    column-count: 2;
    
  }
}

@media (max-width: 480px) {
  .gallery {
    column-count: 1;
    
  }
}

/*model*/

.model {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease;
  background-color: #000;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}
